let scrolltriggerLine = null;
let disable_interactivity = false;
let disable_approach_desktop = false;
let buttonFloatOffset;

(function($) {
  "use strict";
	scrolltriggerLine = {
		getButtonOffsetTop: function() {
			buttonFloatOffset = document.querySelector('.btn__float').offsetTop;

			return buttonFloatOffset;
		},
		getScrollTriggerEndValue: function() {
			let documentHeight = Math.max(
				document.body.scrollHeight, 
				document.body.clientHeight, 
				document.body.offsetHeight, 
				document.documentElement.scrollHeight, 
				document.documentElement.offsetHeight, 
				document.documentElement.clientHeight);

			// Section related
			let getHeightHero = 0;
			let getHeightExplore = 0;
			let getHeightFooter = 0;
			
			// Totals & offsets
			let offsetPadding = 100;
			let totalHeight = 0;
			let totalHeightWithOffset = 0;
			
			// if we have other scrolltrigger section we do something different
			if(document.getElementsByClassName('approach').length > 0) {
				totalHeightWithOffset = 10000;
			} else {
				// Hero
				if(document.getElementsByClassName('hero').length > 0) {
					getHeightHero = document.querySelector(".hero").offsetHeight;
				}

				// Explore
				if(document.getElementsByClassName('explore').length > 0) {
					getHeightExplore = document.querySelector(".explore").offsetHeight;
				}

				// Footer
				if(document.getElementsByClassName('footer').length > 0) {
					getHeightFooter = document.querySelector(".footer").offsetHeight;
				}

				// Calculate scrollTriggerHeight
				totalHeight = documentHeight - getHeightHero - getHeightExplore - getHeightFooter;
				totalHeightWithOffset = totalHeight - offsetPadding;
			}

			return totalHeightWithOffset;
		},
		onReady: function() {

			// floating button, sticky sidebars
			if(theme.getScreenWidth() < 800) {
				disable_interactivity = true;
			}

			// toggle between desktop vs mobile approach animation
			if(theme.getScreenWidth() < 800) {
				disable_approach_desktop = true;
			}

			// Content sidebar - left
			if(document.getElementsByClassName('sidebar__nav').length > 0 && !disable_interactivity) {
				let tl_Button_Float =gsap.timeline({
					scrollTrigger: {
						trigger: ".sidebar__nav",
						//markers: true,
						scrub: 0,
						start: "-40px",
						//endTrigger: "html",
						//end:"bottom top",
						//end: () => "+=" + Math.max(0, document.querySelector(".section .col.col-md-9 .content").offsetHeight - window.innerHeight),
						end: () => "+=" + scrolltriggerLine.getScrollTriggerEndValue(),
						pin: true,
						}
					})
			}

			// Content sidebar - right
			if(document.getElementsByClassName('content__sidebar').length > 0 && !disable_interactivity) {
				const contentHeight = Math.max(0, (document.querySelector(".section .col.col-md-8").offsetHeight) - document.querySelector(".content__sidebar").offsetHeight);

				let tl_Button_Float =gsap.timeline({
					scrollTrigger: {
						trigger: ".content__sidebar",
						//markers: true,
						scrub: 0,
						start: "40px",
						//endTrigger: "html",
						//end: () => "+=" + Math.max(0, (document.querySelector(".section .col.col-md-8").offsetHeight + document.querySelector(".section.content div.content.content__indented").offsetHeight) - window.innerHeight),
						end: () => "+=" + contentHeight,
						//end: () => "+=" + scrolltriggerLine.getScrollTriggerEndValue(),
						pin: true,
						}
					})
			}

			// Button float
			if(document.getElementsByClassName('btn__float').length > 0 && !disable_interactivity) {

				let tl_Button_Float =gsap.timeline({
					scrollTrigger: {
						trigger: ".btn__float",
						//markers: true,
						scrub: 0,
						start: "top +=74%",
						endTrigger:"footer",
						end: "+=10000px top",
						//end: () => "+=" + scrolltriggerLine.getScrollTriggerEndValue(),
						//end: () => "+=" + Math.max(0, (document.querySelector(".main__content").offsetHeight)),
						pin: true,
						}
					})
			}

			/**
			***** Line animation
			**/
			if(document.getElementsByClassName('approach').length > 0 ) {
				gsap.registerPlugin(MotionPathPlugin, DrawSVGPlugin, ScrollTrigger);
			}

			// Desktop animation
			if(document.getElementsByClassName('approach').length > 0 && !disable_approach_desktop) {
				/**
				***** Frame 1
				**/
				if(document.getElementsByClassName('approach__frame1').length > 0) {
					gsap.set("#frame1_motion", { scale: 0.87, autoAlpha: 1 });
					gsap.set("#frame1_motion .circle__container", {transformOrigin: "50% 50%"});

					let tl_frame1 =gsap.timeline({
						scrollTrigger: {
							trigger: ".approach__frame1",
							//markers: true,
							scrub: 1,
							start: "top",
							end: () => "+=" + Math.max(0, document.querySelector(".approach__frame1").offsetHeight),
							pin: true,
							}
						})
						tl_frame1.to("#frame1_motion", {
							ease: "none",
							motionPath: {
							path: "#frame1_path",
							align: "#frame1_path",
							alignOrigin: [0.5, 0.5],
							autoRotate: 90,
							}
						}).from ("#frame1_path", {drawSVG: "0%", ease: "none"},"<");
				}

				/**
				***** Frame 2
				**/
				if(document.getElementsByClassName('approach__frame2').length > 0) {
					gsap.set("#frame2_motion", { scale: 0.87, autoAlpha: 1 });
					gsap.set("#frame2_motion .circle__container", {transformOrigin: "50% 50%"});
				
					let tl_frame2 =gsap.timeline({
						scrollTrigger: {
							trigger: ".approach__frame2",
							//markers: true,
							scrub: 1,
							start: "top",
							end: () => "+=" + Math.max(0, document.querySelector(".approach__frame2").offsetHeight),
							pin: true,
							}
						})
						tl_frame2.to("#frame2_motion", {
							ease: "none",
							motionPath: {
							path: "#frame2_path",
							align: "#frame2_path",
							alignOrigin: [0.5, 0.5],
							autoRotate: 90,
							}
						}).from ("#frame2_path", {drawSVG: "0%", ease: "none"},"<");
				}


				/**
				***** Frame 3
				**/
				if(document.getElementsByClassName('approach__frame3').length > 0) {

					gsap.set(["#frame3_motion1", "#frame3_motion2"], { scale: 0.87, autoAlpha: 1 });
					gsap.set(["#frame3_motion3"], { scale: 0.88, autoAlpha: 1 });
					gsap.set(["#frame3_motion1 .circle__container", "#frame3_motion2 .circle__container", "#frame3_motion3 .circle__container"], {transformOrigin: "50% 50%"});
			
					let tl_frame3 =gsap.timeline({
						scrollTrigger: {
							trigger: ".approach__frame3",
							//markers: true,
							scrub: 1,
							start: "top",
							end: () => "+=" + Math.max(0, document.querySelector(".approach__frame3").offsetHeight),
							pin: true,
							}
						})
						tl_frame3.to("#frame3_motion1", {

							ease: "none",
							motionPath: {
								path: "#frame3_path1",
								align: "#frame3_path1",
								alignOrigin: [0.5, 0.5],
								autoRotate: 90,
							}
						})
						.from ("#frame3_path1", {

							drawSVG: "0%",
							ease: "none"
						},"0")
						.to("#frame3_motion2", {

							ease: "none",
							motionPath: {
								path: "#frame3_path2",
								align: "#frame3_path2",
								alignOrigin: [0.5, 0.5],
								autoRotate: 90,
							}
						},"0")
						.from("#frame3_path2", {

							drawSVG: "0%",
							ease: "none"
						},"0")
						.from("#frame3_motion3", {
							duration: 0.1,
							autoAlpha: 0,
							ease: "none"
						})
						.set(["#frame3_motion1", "#frame3_motion2", "#frame3_path1", "#frame3_path2"], {
							autoAlpha: 0
						})
						.set(".path__exit", {
							autoAlpha: 1
						})
						.to(".exit__left", {
							duration: 0.4,
							x: "110%",
							ease: "none"
						})
						.to(".exit__right", {
							duration: 0.4,
							x: "-110%",
							ease: "none"
						},"-=0.4")
				}

					// reveal SVG visibility (hidden before timeline is appended, to avoid flicker)
					const lineAnimationContainer = document.querySelectorAll('.line__animation');

					for( let i=0; i < lineAnimationContainer.length; i++ ) {
						lineAnimationContainer[i].style.visibility = 'visible';
					}
			}

			// Mobile animation
			if(document.getElementsByClassName('approach').length > 0 && disable_approach_desktop) {
				/**
				***** Frame 1
				**/
				if(document.getElementsByClassName('approach__frame1').length > 0) {

					gsap.set("#frame1_motion1_alt", { scale: 2.3, autoAlpha: 1 });
					gsap.set("#frame1_motion1_alt .circle__container", {transformOrigin: "50% 50%"});

					let tl_frame1_alt =gsap.timeline({
						scrollTrigger: {
							trigger: ".approach__frame1",
							//markers: true,
							scrub: 1,
							start: "top",
							end: () => "+=" + Math.max(0, document.querySelector(".approach__frame1").offsetHeight),
							pin: true,
							}
						})
						tl_frame1_alt.to("#frame1_motion1_alt", {
							ease: "none",
							motionPath: {
							path: "#frame1_path1_alt",
							align: "#frame1_path1_alt",
							alignOrigin: [0.5, 0.5],
							autoRotate: 90,
							}
						}).from("#frame1_path1_alt", {drawSVG: "0%", ease: "none"},"<");
				}

				/**
				***** Frame 2
				**/
				if(document.getElementsByClassName('approach__frame2').length > 0) {

					gsap.set("#frame2_motion1_alt", { scale: 2.3, autoAlpha: 1 });
					gsap.set("#frame2_motion1_alt .circle__container", {transformOrigin: "50% 50%"});

					let tl_frame2_alt =gsap.timeline({
						scrollTrigger: {
							trigger: ".approach__frame2",
							//markers: true,
							scrub: 1,
							start: "top",
							end: () => "+=" + Math.max(0, document.querySelector(".approach__frame2").offsetHeight),
							pin: true,
							}
						})
						tl_frame2_alt.to("#frame2_motion1_alt", {
							ease: "none",
							motionPath: {
							path: "#frame2_path1_alt",
							align: "#frame2_path1_alt",
							alignOrigin: [0.5, 0.5],
							autoRotate: 90,
							}
						}).from("#frame2_path1_alt", {drawSVG: "0%", ease: "none"},"<");
				}

				/**
				***** Frame 3
				**/
				if(document.getElementsByClassName('approach__frame3').length > 0) {
					
					gsap.set(["#frame3_motion1_alt", "#frame3_motion2_alt"], { scale: 2.3, autoAlpha: 1 });
					gsap.set(["#frame3_motion1_alt .circle__container", "#frame3_motion2_alt .circle__container"], {transformOrigin: "50% 50%"});
			
					let tl_frame3 =gsap.timeline({
						scrollTrigger: {
							trigger: ".approach__frame3",
							//markers: true,
							scrub: 1,
							start: "top",
							end: () => "+=" + Math.max(0, document.querySelector(".approach__frame3").offsetHeight),
							pin: true,
							}
						})
						tl_frame3.to("#frame3_motion1_alt", {

							ease: "none",
							motionPath: {
								path: "#frame3_path1_alt",
								align: "#frame3_path1_alt",
								alignOrigin: [0.5, 0.5],
								autoRotate: 90,
							}
						})
						.from ("#frame3_path1_alt", {

							drawSVG: "0%",
							ease: "none"
						},"0")
						.to("#frame3_motion2_alt", {

							ease: "none",
							motionPath: {
								path: "#frame3_path2_alt",
								align: "#frame3_path2_alt",
								alignOrigin: [0.5, 0.5],
								autoRotate: 90,
							}
						},"0")
						.from("#frame3_path2_alt", {

							drawSVG: "0%",
							ease: "none"
						},"0")
				}
			}
		}
	};

	document.addEventListener('DOMContentLoaded', scrolltriggerLine.onReady);
	window.addEventListener('load', scrolltriggerLine.onLoad);
})();