let theme = null;

// Headroom menu gesture
//let headroomInstance;

/**
 * Store the scroll top position as applying overflow:hidden to the body makes it jump to 0
 * @type int
 */
let scrollPos;

(function($) {
	"use strict";
  
	  theme = {
        refreshScrollPosition: function() {
            scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        },
		getScreenHeight: function() {
			let screenHeight = window.innerHeight
				|| document.documentElement.clientHeight
				|| document.body.clientHeight;

			return screenHeight;
		},
		getScreenWidth: function() {
			let screenWidth = window.innerWidth
				|| document.documentElement.clientWidth
				|| document.body.clientWidth;

			return screenWidth;
		},
		getParam: function (name, url) {
		  if (!url) url = window.location.href;
		  if (name) {
		    url = url.toLowerCase(); // This is just to avoid case sensitiveness
		    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase(); // This is just to avoid case sensitiveness for query parameter name
		    var regex = new RegExp("[#\&]" + name + "(=([^&#]*)|&|#|$)"),
		      results = regex.exec(url);
		    if (!results) return null;
		    if (!results[2]) return '';
		    return decodeURIComponent(results[2].replace(/\+/g, " "));
		  } else {
		    return url.split('?').pop();
		  }
		},
		toggleOverflow: function(state) {
			if(state) {
				document.querySelector('body').classList.add('mobile-menu-opened');
				document.querySelector('html').style.overflow = "hidden";
			} else {
				document.querySelector('body').classList.remove('mobile-menu-opened');
				document.querySelector('html').style.overflow = "";
			}
		},
		initMenu: function() {
			theme.refreshScrollPosition(); // refresh scrollPos for when we close the menu

			// toggle overflow
			theme.toggleOverflow(true);

			// animate menu items
			const menu_items = document.querySelectorAll('.main-menu li');
			const menu_footer = document.querySelector('header .footer .section-inner');
			const menu_search = document.querySelector('header .search');

			gsap.fromTo([menu_items, menu_footer, menu_search],
			{
				autoAlpha: 0,
			},
			{
				delay: 0,
				duration: 0.6,
				autoAlpha: 1,
				ease: "power2.out"
			});
/*			
			gsap.fromTo(document.querySelectorAll('.main-menu li'),
			{
				autoAlpha: 0,
				y: -10,
				x: -40
			},
			{
				delay: 0.2,
				duration: 0.4,
				autoAlpha: 1,
				x: 0,
				y: 0,
				stagger: 0.05,
				ease: "power2.out"
			});

			gsap.fromTo(document.querySelector('header .footer .section-inner'),
			{
				autoAlpha: 0,
			},
			{
				delay: 1,
				duration: 0.5,
				autoAlpha: 1,
				ease: "power2.out"
			});
*/
			
		},
		closeMobileMenu: function() {
			// toggle overflow
			theme.toggleOverflow(false);

			// when removing overflow, reset to initial scroll position
			document.body.scrollTop = scrollPos;

			// clear all CSS properties
			gsap.set(document.querySelectorAll('.main-menu li'), {clearProps: 'all'});
			gsap.set(document.querySelector('header .footer .section-inner'), {clearProps: 'all'});
		},
		cloneFooter: function() {
			const footer = document.querySelector('.footer');
			const footerCloned = footer.cloneNode(true);

			document.querySelector('header').insertAdjacentHTML('beforeend',footerCloned.outerHTML);
		},
		toogleOverlay: function(state, targetContainer) {
			if(state) {
				// toggle overflow
				document.querySelector('body').classList.add('mobile-menu-opened');
				document.querySelector('html').style.overflow = "hidden";

				targetContainer.classList.add('active');
			} else {
				targetContainer.classList.remove('active');
			}
		},
		onReady: function() {

			/**
			***** Misc
			**/
			let hasFooterBeenClonedToMenu;

			/**
			***** Menu
			**/
			const headerMain = document.querySelector('header');
			const closeBtn = document.querySelector('.close-x');

			/**
			***** Menu expand
			**/
			const menuExpandContainer = document.querySelector('.main-menu__expand');
			//const menuExpandDots = document.querySelectorAll('.main-menu__expand_dot');

			// Animation
			/*
			gsap.set(menuExpandDots, {rotation: 0.1});
			let tl_menu = gsap.timeline({paused: true});

			tl_menu.to(menuExpandDots, {
				duration: 0.1,
				scale: 1.8,
				stagger: 0.05,
				//ease: Back.easeOut.config(1)
				//ease: Elastic.easeInOut.config( 1, 0.3)
				ease: Power2.easeOut
			});
			*/

			// Toggle menu
			menuExpandContainer.addEventListener('click', (event) => {
				event.preventDefault();

				if(!headerMain.classList.contains('menu__active')) {
					theme.initMenu();
				}

				headerMain.classList.add('menu__active');
			});
			closeBtn.addEventListener('click', (event) => {
				event.preventDefault();

				if(headerMain.classList.contains('menu__active')) {
					theme.closeMobileMenu();
				}

				headerMain.classList.remove('menu__active');
			});
			menuExpandContainer.addEventListener('mouseenter', (event) => {
				//tl_menu.play();

				if(!hasFooterBeenClonedToMenu) {
					hasFooterBeenClonedToMenu = true;
					theme.cloneFooter();
				}
			});
			/*
			menuExpandContainer.addEventListener('mouseleave', (event) => {
				tl_menu.reverse();
			});
			*/

			// Toggle collapsed sub menu items
			const menuSubMenuContainer = document.querySelectorAll('#menu-primary-navigation ul'); //corresponds to the sub menu container

			if(menuSubMenuContainer.length > 0) {
				for(let i = 0; i < menuSubMenuContainer.length; i++) {
					menuSubMenuContainer[i].addEventListener('click', (event) => {
						const thatTagName = event.target.tagName;

						if(thatTagName == 'UL') {
							event.preventDefault();
							const thatContainer = menuSubMenuContainer[i];
							thatContainer.classList.toggle('submenu__active');
						}
					});
				}
			}

			/**
			***** Scroll
			**/
			const scrollTop = document.querySelector('.gototop');

			scrollTop.addEventListener('click', (event) => {
				event.preventDefault();

			    window.scrollTo({
			        top: 0,
			        behavior: "smooth"
			    });
			});

			/**
			***** Overlay
			**/
			const btnOverlay = document.querySelector('.btn__overlay');

			if(btnOverlay) {
				const overlayContainer = document.querySelector('.overlay__container');
				const btnOverlayClose = document.querySelector('.overlay .close-x');

				btnOverlay.addEventListener('click', (event) => {
					event.preventDefault();

					theme.toggleOverflow(true);
					overlayContainer.classList.toggle('overlay__active');
				});

				btnOverlayClose.addEventListener('click', (event) => {
					event.preventDefault();

					theme.toggleOverflow(false);
					overlayContainer.classList.toggle('overlay__active');
				});
			}

			/*
			***
			***** modal toggle
			***
			*/
			const getModal = document.getElementsByClassName("mso__modal");

			if(getModal.length > 0) {
				const modal = document.querySelector('.mso__modal');
				const modalClose = modal.querySelector('.mso__modal-close');

				//init
				modal.classList.add('mso__modal-init');

				modalClose.addEventListener('click', (event) => {
					modal.classList.add('mso__modal-closed');
				});
			}

		},
		onLoad: function() {
		    //console.log("onLoad");
		},
		onResize: function() {
			//console.log("onResize");
		}
	};

	document.addEventListener('DOMContentLoaded', theme.onReady);
	window.addEventListener('load', theme.onLoad);
	window.addEventListener("resize", theme.onResize);
})();